import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

import Vue from 'vue'
import QfitList from './QfitList.vue'
import OpenclassList from './OpenclassList.vue'

Vue.config.devtools = true
Vue.config.productionTip = false

import './assets/app.scss'

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('qfit-list')) {
    new Vue({
      render: h => h(QfitList),
    }).$mount('#qfit-list')
  }
  if (document.getElementById('openclass-list')) {
    new Vue({
      render: h => h(OpenclassList),
    }).$mount('#openclass-list')
  }
})
