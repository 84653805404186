<template>
  <div class="qfit-list-container">
    <qfit-control-pane v-model="filters" @input="search"></qfit-control-pane>
    <div class="container content-area qfit-list">
      <qfit-card-list ref="list" src="/project/qfit"></qfit-card-list>
    </div>
  </div>
</template>

<script>
import QfitControlPane from './components/QfitControlPane'
import QfitCardList from './components/QfitCardList'
export default {
  name: 'QfitList',
  components: {
    QfitControlPane,
    QfitCardList
  },
  data () {
    return {
      filters: {}
    }
  },
  methods: {
    search () {
      this.$refs.list.get = this.filters
      this.$refs.list.search(1, true)
    }
  }
}
</script>

<style scoped>
.qfit-list-container {
  min-height: 100vh;
  display: flex;
}
.qfit-list {
  /* margin-left: 340px; */
  flex-grow: 1;
}
@media screen and (max-width: 1140px) {
  .qfit-list {
    /* margin-left: 320px; */
  }
}
@media screen and (max-width: 1024px) {
  .qfit-list-container {
    display: block;
  }
  .qfit-list {
    margin-top: 30px;
    margin-left: 0;
  }
}
</style>
