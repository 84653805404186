import { render, staticRenderFns } from "./OpenclassControlPane.vue?vue&type=template&id=60d5d0f2&scoped=true&"
import script from "./OpenclassControlPane.vue?vue&type=script&lang=js&"
export * from "./OpenclassControlPane.vue?vue&type=script&lang=js&"
import style0 from "./OpenclassControlPane.vue?vue&type=style&index=0&id=60d5d0f2&scoped=true&lang=css&"
import style1 from "./OpenclassControlPane.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d5d0f2",
  null
  
)

export default component.exports