<template>
    <section class="gallery-board" style="padding-top: 0;">
        <ul v-if="result.list.length > 0" class="card-list">
            <li v-for="(row, index) in result.list" :key="index" class="card-item">
                <a :href="'/project/qfit/view?id=' + row.idx + '&query=' + query()" class="card">
                    <div class="card-cover" :style="{'background-image': 'url(' + row.thumbnail + ')'}">
                        <h3 class="card-title">{{row.title}}</h3>
                    </div>
                    <div class="card-body">
                        <div class="card-body-contents">{{sector1[row.sector_1]}} | {{row.term}}개월 | {{mapLevel(row.position).join(', ')}}</div>
                    </div>
                </a>
            </li>
        </ul>
        <div v-else style="text-align: center;">검색된 데이터가 없습니다. 이노핏 파트너스에 문의주세요.
			<div class="btn_container">
				<div>
					<a href="/contact?param=consulting" class="btn_primary"><span class="mdi mdi-send" style="font-family: arial;"></span> 문의하기</a>
				</div>
			</div>
		</div>

        <div class="board-footer" style="text-align: center;">
            <ul class="pagination">
                <li v-for="page in pageInfo.view" :key="page" :class="{active: page.page == pageInfo.page.current}">
                    <a v-if="page.page == pageInfo.page.current" href="javascript:void(0)">{{page.text}}</a>
                    <a v-else :href="basePath + '?page=' + page.page + (pageUrlParam ? '&' + pageUrlParam : '')" @click.prevent="search(page.page)">{{page.text}}</a>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import Qs from 'qs'

export default {
    name: 'QfitCardList',
    props: {
        src: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            basePath: '',
            content: '',
            urlParam: '',
            pageUrlParam: '',
            pageInfo: {
                page: {
                    count: 0,
                    current: 1,
                    last: 1,
                    limit: 10,
                    next: false,
                    prev: false,
                    remain: 0,
                    start: 1
                },
                view: [],
                rows: {
                    count: 0,
                    endOffset: 0,
                    limit: 12,
                    startOffset: 0
                }
            },
            result: {
                list: [],
                rowNum: 0,
                totalCnt: 0
            },
            get: {
                page: 1
            },
            level: {
                'E1': '사원',
                'E2': '주임',
                'E3': '대리',
                'E4': '과장',
                'E5': '차장',
                'E6': '부장',
                'E7': '임원~CEO',
                'E8': '전직원',
                'E9': '핵심인재',
                'E10': '부서장'
            },
            sector1: {
                'S1_000': '서비스',
                'S1_002': '금융',
                'S1_003': 'IT·정보통신',
                'S1_004': '판매·유통',
                'S1_005': '제조·생산·화학',
                'S1_006': '교육',
                'S1_007': '건설',
                'S1_008': '의료·제약',
                'S1_009': '미디어·광고',
                'S1_010': '문화·예술·디자인',
                'S1_011': '공공기관·협회',
                'S1_012': '그룹사'
            },
            sector2: {
                'S2_000': '인터넷서비스/핀테크',
                'S2_001': '방송/OTT',
                'S2_002': '광고업',
                'S2_003': '엔터테인먼트',
                'S2_004': '게임',
                'S2_005': '여행/호텔/카지노',
                'S2_006': '교육/에듀테크',
                'S2_007': '스마트폰/부품',
                'S2_008': '통신/5G',
                'S2_009': '반도체/장비/소재',
                'S2_010': '디스플레이/마이크로LED',
                'S2_011': '가전/스마트폰',
                'S2_012': 'IT서비스/스마트팩토리',
                'S2_013': '은행',
                'S2_014': '증권',
                'S2_015': '보험',
                'S2_016': '부동산리츠',
                'S2_017': '자동차/친환경차',
                'S2_018': '자동차부품/타이어',
                'S2_019': '해운/조선',
                'S2_020': '항공',
                'S2_021': '육운/택배',
                'S2_022': '상사',
                'S2_023': '석유화학',
                'S2_024': '정유',
                'S2_025': '전력/가스',
                'S2_026': '제약/바이오',
                'S2_027': '화학섬유/탄소섬유',
                'S2_028': '건설/부동산',
                'S2_029': '건자재/시멘트/가구',
                'S2_030': '철강/비철금속',
                'S2_031': '기계/중장비',
                'S2_032': '방산',
                'S2_033': '소매/유통',
                'S2_034': 'TV홈쇼핑/온라인쇼핑몰',
                'S2_035': '가공식품/라면/제과',
                'S2_036': '음료/우유/커피/생수',
                'S2_037': '주류/담배',
                'S2_038': '패션',
                'S2_039': '화장품'
            }
        }
    },
    methods: {
        query () {
            return encodeURIComponent(location.search)
        },
        mapLevel (levels) {
            let _levels = []
            if (levels) {
                JSON.parse(levels).forEach(level => {
                    _levels.push(this.level[level])
                })
            }
            return _levels
        },
        render (data) {
            this.basePath = data.basePath
            this.content = data.content
            this.urlParam = data.url_param
            this.pageUrlParam = data.page_url_param
            this.pageInfo = data.page_info
            this.result = data.result
            if (!data.get.length) {
                this.get = data.get
            }
        },
        search (page, withoutState) {
            this.get.page = page
            axios.get(`${this.src}_list_json?${Qs.stringify(this.get)}`).then(response => {
                this.render(response.data)
                if (withoutState === true) {
                    history.replaceState(response.data, '', `${this.src}?${Qs.stringify(this.get)}`)
                } else {
                    history.pushState(response.data, '', `${this.src}?${Qs.stringify(this.get)}`)
                }
            })
        }
    },
    mounted () {
        window.addEventListener('popstate', (event) => {
            this.render(event.state)
        })
        if (location.search.length > 0) {
            this.get = Qs.parse(location.search.substr(1))
        }
    }
}
</script>

<style scoped>
.card-list {
    font-family: 'Noto Sans KR', sans-serif;
    display: flex;
    flex-wrap: wrap;
}

.card-list .card-item {
    list-style: none;
    width: 25%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.card-list .card {
    border-radius: 20px;
    box-shadow: 0 3px 15px 0 rgba(28, 30, 34, 0.13);
    width: calc(100% - 20px);
    max-width: 307px;
    height: 310px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.card-list .card .card-cover {
    height: 210px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
}
.card-list .card .card-cover:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
}
.card-list .card .card-cover .card-title {
    font-size: 25px;
    font-weight: bold;
    color: #ffffff;
    position: relative;
	word-break: keep-all;
}
.card-list .card .card-body {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 20px;
    color: #000000;
}
.card-list .card .card-body .card-body-contents {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
	.btn_container {
		display: flex;
		justify-content: center;
		margin-top: 10px;
	}

	.btn_container .btn_primary {

		display: block;
		width: 150px;
		border-radius: 4px;
		font-size: 20px;
		font-weight: bold;
		line-height: 40px;
		text-align: center;

		background-color: #89333c;
		color: #ffffff;
	}

@media screen and (max-width: 1480px) {
    .card-list .card-item {
        width: 33%;
    }
}
@media screen and (max-width: 1280px) {
    .card-list .card {
        width: calc(100% - 25px);
        height: 310px;
    }
    .card-list .card .card-cover .card-title {
        font-size: 25px;
    }
    .card-list .card .card-body {
        font-size: 18px;
    }
    .card-list .card .card-cover {
        height: 230px;
    }
}
@media screen and (max-width: 768px) {
    .card-list .card-item {
        width: 50%;
    }
}
@media screen and (max-width: 640px) {
    .card-list .card {
        width: calc(100% - 30px);
    }
}
@media screen and (max-width: 430px) {
    .card-list .card {
        width: calc(100% - 10px);
        height: 185px;
    }
    .card-list .card .card-cover {
        height: 125px;
    }
    .card-list .card .card-cover .card-title {
        font-size: 14px;
    }
    .card-list .card .card-body {
        font-size: 12px;
    }
}
</style>
