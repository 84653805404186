<template>
    <form class="control-pane" @scroll.stop>
		<div class="mobile-handler">
			<button @click="toggleActive" type="button" class="uk-button uk-button-default" style="padding: 0 15px;">
				<span uk-icon="settings"></span>
				필터
			</button>
			<span style="margin-left: 15px; font-size: 14px;">찾고싶은 정보를 선택해보세요</span>
		</div>
        <fieldset class="uk-fieldset filter-list">
            <div class="pane_pc_head_txt"><span class="mdi mdi-tune"></span> 찾고싶은 정보를<br>아래에서 선택해보세요</div>
            <div v-for="filter in filters" :key="filter.key" class="filter-item">
                <div v-if="filter.type === 'checkbox'" class="filter-checkbox">
                    <div class="filter-title">{{filter.title}}</div>
                    <div style="margin-top: 10px;">
                        <button
                            v-for="(value, index) in filter.values" :key="index"
                            @click="toggle(filter.name, value.value)"
                            :class="{'uk-button-primary': selected(filter.name, value.value), 'uk-button-secondary': !selected(filter.name, value.value)}"
                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                            type="button" class="uk-button control-pane-button"
                        >{{value.name}}</button>
                    </div>
                </div>
                <div v-if="filter.type === 'slider'" class="filter-slider">
                    <div class="filter-title">{{filter.title}}</div>
                    <div style="margin: 10px 10px 0 10px;">{{filter.formatter ? filter.formatter(filter.value) : filter.value}}</div>
                    <div style="margin: 10px 20px 0;">
                        <vue-slider
                            v-model="filter.value"
                            :min="filter.range[0]"
                            :max="filter.range[1]"
                            :min-range="filter.minRange"
                            :interval="filter.step"
                            :disabled="filter.disabled"
                            tooltip="none"
                            v-bind="sliderOptions"
                            @change="(filter.change && filter.change(filter)); inputRange(filter.name, filter.value)"
                        />
                    </div>
                </div>
                <div v-if="filter.type === 'partTitle'" class="filter-part-title">{{filter.title}}</div>
                <div v-if="filter.type === 'rateSlider'" class="filter-rate-slider">
                    <div class="filter-title">
                        <div>
                            {{filter.title}}
                            <span v-if="filter.tooltip" uk-icon="icon: info;" :uk-tooltip="filter.tooltip"></span>
                        </div>
                    </div>
                    <div style="margin: 10px 10px 0 10px; display: flex; justify-content: space-between;">
                        <div>{{filter.item1}} {{filter.formatter ? filter.formatter(filter.value) : filter.value}}</div>
                        <div>{{filter.formatter ? filter.formatter(100 - filter.value) : 100 - filter.value}} {{filter.item2}}</div>
                    </div>
                    <div style="margin: 10px 20px 0;">
                        <vue-slider
                            v-model="filter.value"
                            :min="filter.range[0]"
                            :max="filter.range[1]"
                            :min-range="filter.minRange"
                            :interval="filter.step"
                            :disabled="filter.disabled"
                            :rail-style="filter.railStyle"
                            tooltip="none"
                            v-bind="sliderOptions"
                            @change="(filter.change && filter.change(filter)); inputRange(filter.name, filter.value)"
                        />
                    </div>
                </div>
                <div v-if="filter.type === 'bizTech'">
                    <div class="filter-title">
                        <div>
                            {{filter.title}}
                            <span v-if="filter.tooltip" uk-icon="icon: info;" :uk-tooltip="filter.tooltip"></span>
                        </div>
                    </div>
                    <div style="margin-top: 10px;">
                        <div style="display: flex; font-weight: bold;">
                            <div class="filter-subtitle-biztech" style="width: 120px; text-align: center; color: #022a67;">Biz</div>
                            <div class="filter-subtitle-biztech" style="width: 120px; text-align: center; color: #7e2e33;">Tech</div>
                        </div>
                        <div v-for="(value, index) in filter.values" :key="index">
                            <button
                                @click="toggle(filter.name, 'BIZ_' + value.value)"
                                :class="{'uk-button-biz': selected(filter.name, 'BIZ_' + value.value), 'uk-button-secondary': !selected(filter.name, 'BIZ_' + value.value)}"
                                type="button" class="uk-button control-pane-button"
								v-html="value.name"
                            ></button>
                            <button
                                @click="toggle(filter.name, 'TECH_' + value.value)"
                                :class="{'uk-button-primary': selected(filter.name, 'TECH_' + value.value), 'uk-button-secondary': !selected(filter.name, 'TECH_' + value.value)}"
                                type="button" class="uk-button control-pane-button"
								v-html="value.name"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        <div class="control-pane-footer">
            <div>
                <button @click="clear" type="button" class="uk-button uk-button-default uk-width-1-1">초기화</button>
            </div>
            <div>
                <button @click="toggleActive" type="button" class="uk-button uk-button-default uk-width-1-1">확인</button>
            </div>
        </div>
    </form>
</template>

<script>
import UIkit from 'uikit'
import Qs from 'qs'
import axios from 'axios'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
export default {
    name: 'QfitControlPane',
    components: {
        VueSlider
    },
    props: {
        value: {
            type: Object
        }
    },
    data () {
        return {
            filters: [
                {
                    type: 'checkbox',
                    title: '키워드',
                    name: 'keyword',
                    values: []
                },
                {
                    type: 'checkbox',
                    title: '대상',
                    name: 'level',
                    values: [
                        { name: '사원', value: 'E1' },
                        { name: '주임', value: 'E2' },
                        { name: '대리', value: 'E3' },
                        { name: '과장', value: 'E4' },
                        { name: '차장', value: 'E5' },
                        { name: '부장', value: 'E6' },
                        { name: '임원~CEO', value: 'E7' },
                        { name: '전직원', value: 'E8' },
                        { name: '핵심인재', value: 'E9' },
                        { name: '부서장', value: 'E10' }
                    ]
                },
                // {
                //     type: 'slider',
                //     title: '총견적',
                //     name: 'estimate',
                //     range: [0, 35],
                //     step: 5,
                //     minRange: 5,
                //     value: [0, 35],
                //     formatter: val => {
                //         if (val[0] == 0 && val[1] == 35) {
                //             return '전체 (5천만~3억 이상)'
                //         } else if (val[0] == 0) {
                //             return this.formatCurrecy(val[1]) + ' 미만'
                //         } else if (val[1] == 35) {
                //             return this.formatCurrecy(val[0]) + ' 이상'
                //         } else {
                //             return this.formatCurrecy(val[0]) + ' ~ ' + this.formatCurrecy(val[1])
                //         }
                //     }
                // },
                {
                    type: 'checkbox',
                    title: '인원',
                    name: 'personnel',
                    values: [
                        { name: '1~10명', value: '1to10' },
                        { name: '11~30명', value: '11to30' },
                        { name: '31~50명', value: '31to50' },
                        { name: '51~100명', value: '51to100' },
                        { name: '100~300명', value: '100to300' },
                        { name: '300명 이상', value: '300over' }
                    ]
                },
                // {
                //     type: 'slider',
                //     title: '교육시간',
                //     name: 'education',

				// 	range: [0, 12],
                //     value: [0, 12],
				// 	step: 2,
				// 	minRange: 2,

                //     formatter: val => {
                //         if (val[0] == 0 && val[1] == 12) {
                //             return '전체 (20~100시간 이상)'
                //         } else if (val[0] == 0) {
                //             return `${val[1]}0시간 미만`
                //         } else if (val[1] == 12) {
                //             return `${val[0]}0시간 이상`
                //         } else {
                //             return `${val[0]}0시간 ~ ${val[1]}0시간`
                //         }
                //     }
                // },
                {
                    type: 'partTitle',
                    title: '상세검색'
                },
                {
                    type: 'rateSlider',
                    title: '주제',
                    tooltip: 'Biz : 비즈니스 교육<br/>Tech : 기술중심 교육',
                    item1: 'Biz',
                    item2: 'Tech',
                    name: 'biz',
                    range: [-10, 100],
                    value: -10,
                    step: 10,
                    railStyle: {
                        'background-color': 'rgb(51, 85, 137)'
                    },
                    formatter: (val) => {
                        if (val < 0 || val > 100) {
                            return '전체'
                        } else {
                            return `${val}%`
                        }
                    }
                },
                {
                    type: 'bizTech',
                    title: '방법',
                    tooltip: 'PBL: Project Based Learning​<br/>PBS: Project Based Solving​',
                    name: 'method',
                    values: [
                        { name: 'PBL<br />교육 중심', value: 'PBL' },
                        { name: 'PBS<br />문제해결 중심', value: 'PBS' }
                    ]
                },
                {
                    type: 'bizTech',
                    title: '수준',
                    tooltip: 'Pre-Literacy: DT를 받아들이는 Mindset<br/>Literacy: DT시대에 관한 지식을​ 갖춘 수준<br/>Fluency: DT시대 지식과 기술을​ 적용하여<br/>사업적인 가치를​ 창출하는 수준',
                    name: 'plf',
                    values: [
                        { name: 'Pre Literacy<br />입문', value: 'PL' },
                        { name: 'Literacy<br />기초', value: 'L' },
                        { name: 'Fluency<br />심화', value: 'F' }
                    ]
                }
			],
            sliderOptions: {
                dotOptions: {
                    style: {
                        'margin-top': '-5px',
						'margin-left': '-5px',
                        width: '25px',
                        height: '25px',
                        border: 'solid #a6656c 1px'
                    },
                    tooltipStyle: {
                        'background-color': 'transparent',
                        'border-color': 'transparent',
                        'box-shadow': 'none',
                        'margin-bottom': '-10px',
                        'color': '#4b4b4b',
                        'font-size': '14px',
                        'font-weight': 'bold'
                    },
                    focusStyle: {
                        'box-shadow': '0 0 0 5px rgba(137, 51, 60, .2)'
                    },
                },
                railStyle: {
                    'background-color': '#d4d4d4',
                    height: '6px'
                },
                processStyle: {
                    'background-color': '#89333c'
                }
            },
            timeouts: {}
        }
	},
	methods: {
        getValues () {
            let values = {}
			Object.entries(this.value).forEach(v => {
				values[v[0]] = v[1]
			})
			return values
		},
		selected (name, value) {
            let values = this.getValues()
			return values[name] && values[name].indexOf(value) > -1
		},
		input (name, value) {
			let values = this.getValues()
			if (values[name] !== value) {
				values[name] = value
				this.$emit('input', values)
			}
        },
        inputRange (name, value) {
            if (this.timeouts[name]) {
                clearTimeout(this.timeouts[name])
                delete this.timeouts[name]
            }

            this.timeouts[name] = setTimeout(() => {
                this.input(name, value)
            }, 1000)
        },
		select (name, value) {
			let values = this.getValues()
			if (!values[name]) {
				values[name] = []
			}
			values[name].push(value)
			this.$emit('input', values)
		},
		unselect (name, value) {
			let values = this.getValues()
			values[name] = values[name].filter(v => v !== value)
			if (values[name].length < 1) {
				delete values[name]
			}
			this.$emit('input', values)
		},
		toggle (name, value) {
			if (this.selected(name, value)) {
				this.unselect(name, value)
			} else {
				this.select(name, value)
			}
        },
        formatCurrecy (val) {
            if (val == 0) {
                return '0원'
            } else if (val < 10) {
                return `${val}천만원`
            } else {
                let val1 = String(val).substr(0, 1)
                let val2 = String(val).substr(1, 1)
                if (val2 > 0) {
                    return `${val1}억 ${val2}천만원`
                } else {
                    return `${val1}억`
                }
            }
        },
        clear () {
            this.$emit('input', {})
        },
        toggleActive () {
            document.body.classList.toggle('active-control-pane')
        }
    },
    mounted () {
        document.querySelectorAll('[uk-tooltip]').forEach(element => {
            UIkit.tooltip(element, {
                title: element.getAttribute('uk-tooltip'),
                pos: 'top-left'
            })
        })

        let keywords = window.sessionStorage.getItem('qfit_keyword')
        if (keywords) {
            JSON.parse(keywords).forEach(keyword => {
                this.filters[0].values.push({name: keyword.keyword, value: keyword.keyword})
            })
        } else {
            axios.get(`/project/qfit_keyword_json`).then(response => {
                window.sessionStorage.setItem('qfit_keyword', JSON.stringify(response.data))
                response.data.forEach(keyword => {
                    this.filters[0].values.push({name: keyword.keyword, value: keyword.keyword})
                })
            })
        }
        let query = Qs.parse(location.search.replace(/^\?/, ''))
        let values = {}
        this.filters.forEach(filter => {
            if (filter.value) {
                values[filter.name] = filter.value
            }
        })
        Object.entries(query).forEach(entry => {
            let _index = this.filters.findIndex(filter => filter.name === entry[0])
            if (_index > -1) {
                values[entry[0]] = entry[1]
                this.filters[_index].value = entry[1]
            }
        })
        this.$emit('input', values)
    }
}
</script>

<style scoped>
.control-pane {
	box-sizing: border-box;
	left: 20px;
	top: 0;
	background-color: #ffffff;
	width: 320px;
    background-color: #f9f9f9;
}
.control-pane .uk-fieldset {
    width: 320px;
    margin-bottom: 20px;
}
.control-pane .control-pane-button {
    font-weight: 400;
    width: 114px;
    margin: 3px;
    font-size: 14px;
    padding: 0;
	padding: 10px 0;
    line-height: 1.2;
}
.mobile-handler {
    background-color: #98474c;
	display: none;
    padding: 20px 25px;
    color: #fff;
}
.mobile-handler button {
  color: #fff;
}
.control-pane-footer {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 0 40px;
}
.control-pane-footer > * {
    flex-grow: 1;
}
.control-pane-footer > *:last-child {
    display: none;
}
.control-pane-footer .uk-button {
    font-size: 14px;
    background-color: #f9f9f9;
}
.filter-part-title {
    padding-left: 10px;
    font-size: 24px;
    font-weight: bold;
}
.filter-list > :nth-child(-n + 5) {
    background-color: #f9f9f9;
}
.filter-list > :nth-child(5) {
    padding-bottom: 40px;
}
.filter-list > :nth-child(6) {
    border-top: solid #d2d2d2 1px;
    padding-top: 40px;
}
.filter-item:first-child {
    padding-top: 40px;
    overflow: hidden;
}
.filter-item {
    padding: 20px 40px 0;
}
.filter-title {
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
}
.uk-button-biz {
    background-color: #022a67;
    color: #fff;
}
.pane_pc_head_txt {
  padding: 20px 0 20px 0;
  font-size: 1.2em;
  border-radius: 0 0 35px 0;
  text-align: center;
  background-color: #98474c !important;
  color: #fff;
}
@media screen and (max-width: 1140px) {
    .control-pane {
        left: 0;
    }
}
@media screen and (max-width: 1024px) {
	.mobile-handler {
		display: block;
	}
  .pane_pc_head_txt {
    display: none;
  }
	.control-pane {
		float: none;
		width: 100vw;
		height: auto;
	}
    .control-pane .uk-fieldset {
		display: none;
        width: auto;
        margin-bottom: 100px;
	}
    .active-control-pane .control-pane .uk-fieldset.filter-list {
        margin-top: 0;
    }
    .filter-item {
        padding-left: 20px;
        padding-right: 20px;
    }
    .control-pane-footer {
        display: none;
    }
	.active-control-pane .control-pane {
		min-height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
	}
    .active-control-pane .control-pane-footer {
        display: flex;
        position: fixed;
        bottom: 0;
        padding: 20px;
        margin-bottom: 0;
        background-color: #fff;
        left: 0;
        right: 0;
        z-index: 1000;
    }
    .active-control-pane .control-pane .control-pane-footer > *:last-child {
        display: block;
        margin-left: 20px;
    }
	.active-control-pane .control-pane .uk-fieldset {
		display: block;
        margin-top: 20px;
	}
}
</style>

<style>
footer {
	position: sticky;
}
@media screen and (max-width: 1024px) {
    .active-control-pane {
        height: 100vh;
        overflow-y: hidden;
    }
}
@media screen and (max-width: 450px) {
	.control-pane .control-pane-button {
		width: 45% !important;
	}
	.filter-subtitle-biztech {
		width: 45% !important;
		margin-left: 6px;
	}
}

</style>
