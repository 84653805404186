<template>
  <div class="openclass-list-container">
    <openclass-control-pane v-model="filters" @input="search"></openclass-control-pane>
    <div class="container content-area openclass-list">
      <openclass-card-list ref="list" src="/program/openclass"></openclass-card-list>
    </div>
  </div>
</template>

<script>
import OpenclassControlPane from './components/OpenclassControlPane'
import OpenclassCardList from './components/OpenclassCardList'
export default {
  name: 'OpenclassList',
  components: {
    OpenclassControlPane,
    OpenclassCardList
  },
  data () {
    return {
      filters: {}
    }
  },
  methods: {
    search () {
      this.$refs.list.get = this.filters
      this.$refs.list.search(1, true)
    }
  }
}
</script>

<style scoped>
.openclass-list-container {
  min-height: 100vh;
  display: flex;
}
.openclass-list {
  /* margin-left: 340px; */
  flex-grow: 1;
}
@media screen and (max-width: 1140px) {
  .openclass-list {
    /* margin-left: 320px; */
  }
}
@media screen and (max-width: 1024px) {
  .openclass-list-container {
    display: block;
  }
  .openclass-list {
    margin-top: 30px;
    margin-left: 0;
  }
}
</style>
